<template>
<div class="win-select">
  <span
    class="select-option"
    :style="{
      'width': optionWidth
    }">{{optionTit}}：</span>
  <ul class="select-value">
    <li
      v-for="(item, key) in newlists"
      :key="key"
      :class="{
        'select-active': item.active
      }"
      @click="toSelect(item.value)">{{item.label}}</li>
  </ul>
</div>
</template>
<script>
export default {
  name: 'winSelect',
  props: {
    optionWidth: {
      type: String,
      default: '120px'
    },
    optionTit: {
      type: String,
      required: true,
      default: ''
    },
    lists: {
      type: Array,
      required: true
    },
    currentValue: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'currentValue',
    event: 'change'
  },
  data () {
    return {
      newlists: []
    }
  },
  created () {
    this.newlists = JSON.parse(JSON.stringify(this.lists))
    this.newlists.unshift({
      value: '',
      label: '不限',
      active: true
    })
    // 当v-model存在值时，设置当前值高亮
    this.newlists = this.newlists.map((iterator, key) => {
      return {
        value: iterator.value,
        label: iterator.label,
        // 如果存在默认值，则对应项显示高亮。否则不限高亮
        active: this.currentValue ? iterator.value === this.currentValue : key === 0
      }
    })
  },
  methods: {
    toSelect (value) {
      for (const iterator of this.newlists) {
        if (iterator.value === value) {
          iterator.active = true
          // 当切换时，更新当前值
          this.$emit('change', iterator.value)
        } else {
          iterator.active = false
        }
      }
    }
  }
}
</script>
<style scoped>
.win-select {
  width: 100%;
  color: #bebebe;
  font-size: 14px;
  margin-bottom: 16px;
}
.select-option {
  display: inline-block;
  font-weight: bold;
  vertical-align: top
}
.select-value {
  display: inline-block;
  width: 80%;
}
.select-value li {
  display: inline-block;
  margin: 0 8px 8px 8px;
  padding-bottom: 2px;
  cursor: pointer;
}
.select-value li:hover {
  color: #fff;
  padding-bottom: 2px;
  border-bottom: 2px solid #ffa134;
  transition: all 0.3s ease
}
.select-active {
  color: #fff;
  border-bottom: 2px solid #ffa134;
  transition: all 0.3s ease
}
</style>
