<template>
  <div class="win-cascader">
    <span
      class="cascader-option"
      :style="{
        'width': optionWidth
      }">{{optionTit}}：</span>
    <div class="cascader-value">
      <ul>
        <li
          v-for="(item, key) in newList"
          :key="key"
          :class="{'cascader-active': item.active}"
          @click="selectItem(1, item.value, item.text)">{{item.text}}</li>
      </ul>
      <transition name="el-fade-in">
        <ul
          v-show="newListTwo.length > 0"
          class="cascader-indent">
          <li
            v-for="(item, key) in newListTwo"
            :key="key"
            :class="{'cascader-active': item.active}"
            @click="selectItem(2, item.value, item.text)">{{item.text}}</li>
        </ul>
      </transition>
      <transition name="el-fade-in">
        <ul
          v-show="newListThree.length > 0"
          class="cascader-indent">
          <li
            v-for="(item, key) in newListThree"
            :key="key"
            :class="{'cascader-active': item.active}"
            @click.stop="selectItem(3, item.value, item.text)">{{item.text}}</li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'winCascader',
  data () {
    return {
      newList: [],
      newListTwo: [],
      newListThree: [],
      newOne: '',
      newTwo: '',
      newThree: '',
      newOneStr: '',
      newTwoStr: '',
      newThreeStr: ''
    }
  },
  props: {
    optionWidth: {
      type: String,
      default: '120px'
    },
    optionTit: {
      type: String,
      required: true,
      default: ''
    },
    lists: {
      type: Array
    },
    selectVal: {
      type: Array
    }
  },
  model: {
    prop: 'selectVal',
    event: 'change'
  },
  created () {
    this.newList = JSON.parse(JSON.stringify(this.lists))
    this.newList.unshift({
      value: '',
      text: '不限',
      active: true
    })
    this.newList = this.newList.map((item, key) => {
      // 如果初始值存在零项，则设置一级列表第一项高亮
      if (this.selectVal.length === 0) {
        if (key === 0) {
          item.active = true
        } else {
          item.active = false
        }
      // 如果初始值存在一项，则根据此项获取第二级列表，并对应一级项和二级第一项高亮
      } else if (this.selectVal.length === 1) {
        if (item.value === this.selectVal[0]) {
          this.newListTwo = item.children.map((item2, key2) => {
            if (key2 === 0) {
              item2.active = true
            } else {
              item2.active = false
            }
            return item2
          })
          item.active = true
          this.newOne = item.value
          this.newOneStr = item.text
        } else {
          item.active = false
        }
      // 如果初始值存在两项，则根据此两项分别获取第二、三级列表，并对应一级项、二级和第三级第一项高亮
      } else if (this.selectVal.length === 2) {
        if (item.value === this.selectVal[0]) {
          this.newListTwo = item.children.map((item2) => {
            if (item2.value === this.selectVal[1]) {
              this.newListThree = item2.children.map((item3, key3) => {
                if (key3 === 0) {
                  item3.active = true
                } else {
                  item3.active = false
                }
                return item3
              })
              item2.active = true
              this.newTwo = item2.value
              this.newTwoStr = item2.text
            } else {
              item2.active = false
            }
            return item2
          })
          item.active = true
          this.newOne = item.value
          this.newOneStr = item.text
        } else {
          item.active = false
        }
      // 如果初始值存在两项，则根据此两项分别获取第二、三级列表，并对应一级项、二级、三级高亮
      } else if (this.selectVal.length === 3) {
        if (item.value === this.selectVal[0]) {
          this.newListTwo = item.children.map((item2) => {
            if (item2.value === this.selectVal[1]) {
              this.newListThree = item2.children.map((item3) => {
                if (item3.value === this.selectVal[2]) {
                  item3.active = true
                } else {
                  item3.active = false
                }
                return item3
              })
              item2.active = true
              this.newTwo = item2.value
              this.newTwoStr = item2.text
            } else {
              item2.active = false
            }
            return item2
          })
          item.active = true
          this.newOne = item.value
          this.newOneStr = item.text
        } else {
          item.active = false
        }
      }
      item.children && item.children.unshift({
        value: 0,
        text: '不限'
      })
      return item
    })
  },
  methods: {
    handleReturn () {
      return false
    },
    selectItem (type, value, text) {
      if (type === 1) {
        this.newOne = value
        this.newOneStr = text
        // 非不限
        if (value) {
          // 高亮一级
          this.newList = this.newList.map((item) => {
            if (item.value === value) {
              item.active = true
              // item.children[0].active = true
            } else {
              item.active = false
            }
            return item
          })
          // 获取二级
          const temArr = this.newList.filter((item) => {
            if (item.value === value) {
              return item
            }
          })
          this.newListTwo = temArr.length > 0 ? temArr[0].children : []
          this.newListThree = []
          this.newListTwo = this.newListTwo ? this.newListTwo.map((item, key) => {
            if (key === 0) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          }) : []
          this.$emit('change', [this.newOne])
        } else {
          // 一级不限高亮，清空二三级，并emit空数组给父级
          this.newList = this.newList.map((item, key) => {
            if (key === 0) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
          this.newListTwo = []
          this.newListThree = []
          this.$emit('change', [])
        }
      } else if (type === 2) {
        this.newTwo = value
        this.newTwoStr = text
        // 非不限
        if (value) {
          // 高亮二级
          this.newListTwo = this.newListTwo ? this.newListTwo.map((item) => {
            if (item.value === value) {
              item.active = true
              // item.children[0].active = true
            } else {
              item.active = false
            }
            return item
          }) : []
          // 获取三级
          const temArr = this.newListTwo.filter((item) => {
            if (item.value === value) {
              return item
            }
          })
          if (temArr.length > 0) {
            this.newListThree = temArr[0].children
            if (this.newListThree.length > 0 && this.newListThree[0].text !== '不限') {
              this.newListThree.unshift({
                value: 0,
                text: '不限'
              })
            }
          } else {
            this.newListThree = []
          }
          this.newListThree = this.newListThree.map((item, key) => {
            if (key === 0) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
          this.$emit('change', [this.newOne, this.newTwo])
        } else {
          // 二级不限高亮，清空三级，并emit一级数组给父级
          this.newListTwo = this.newListTwo ? this.newListTwo.map((item, key) => {
            if (key === 0) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          }) : []
          this.newListThree = []
          this.$emit('change', [this.newOne])
        }
      } else if (type === 3) {
        this.newThree = value
        this.newThreeStr = text
        // 非不限
        if (value) {
          // 高亮三级
          this.newListThree = this.newListThree.map((item) => {
            if (item.value === value) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
          this.$emit('change', [this.newOne, this.newTwo, this.newThree])
        } else {
          // 二级不限高亮，清空三级，并emit一级数组给父级
          this.newListThree = this.newListThree.map((item, key) => {
            if (key === 0) {
              item.active = true
            } else {
              item.active = false
            }
            return item
          })
          this.$emit('change', [this.newOne, this.newTwo])
        }
      }
    }
  }
}
</script>
<style scoped>
.win-cascader {
  width: 100%;
  color: #bebebe;
  font-size: 14px;
  margin-bottom: 16px;
}
.cascader-indent {
  margin-bottom: 10px;
  margin-left: 30px;
  padding: 12px 10px 4px 10px;
  background-color: #191d23
}
.cascader-option {
  display: inline-block;
  font-weight: bold;
  vertical-align: top
}
.cascader-value {
  display: inline-block;
  width: 80%;
}
.cascader-value li {
  display: inline-block;
  margin: 0 8px 8px 8px;
  padding-bottom: 2px;
  cursor: pointer;
  border-bottom: 2px solid rgba(255,255,255,0);
}
.cascader-value li:hover {
  color: #fff;
  padding-bottom: 2px;
  border-bottom: 2px solid #ffa134;
  transition: all 0.3s ease
}
.cascader-active {
  color: #fff;
  border-bottom: 2px solid #ffa134 !important;
  transition: all 0.3s ease
}
</style>
