<template>
  <li
    class="list-item pr"
    @click="goProjectDetails(item.projectId)"
  >
    <div class="list-info">
      <a
        target="_blank"
        :title="item.projectName"
      >
        <div class="clearfix">
          <img
            :src="item.minPic"
            :alt="item.projectName"
            :title="item.projectName"
          />
          <div class="list-info-tit ellipsis2">{{item.projectName}}</div>
        </div>
        <p class="ellipsis">{{item.wuYeLx}}{{item.xiangmudangci ? '/' + item.xiangmudangci : ''}}</p>
        <p class="ellipsis">{{item.optionStr}}</p>
      </a>
      <template v-if="item.xmZhuangTai !== 2073">
        <span
          v-if="item.isYiQianYueBrand === 1"
          class="list-tag-blue"
        >品牌清单</span>
        <!-- <span
          v-if="item.isDianPuZhaoZu === 1"
          class="list-tag-blue">店铺招商</span> -->
      </template>
    </div>
  </li>
</template>

<script>
export default {
  name: 'projectListItem',
  data () {
    return {
      canRemark: true
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    goProjectDetails (id) {
      const projectDetailsHref = this.$router.resolve({
        path: '/projectDetail',
        query: {
          id: id,
          source: 1
        }
      })
      window.open('/' + projectDetailsHref.href, '_blank')
    }
  }
}
</script>
<style lang="stylus" scoped>
.list-item
  float left
  width 200px
  height 190px
  margin-right 16px
  margin-bottom 16px
  background-color rgba(255, 255, 255, 1)
  transition all 0.3s ease
  cursor pointer
  &:hover
    box-shadow 0px 1px 6px 0px #bbb
.list-info
  padding-top 10px
  padding-left 10px
  padding-right 5px
  padding-bottom 5px
  img
    float left
    width 55px
    height 55px
    margin-bottom 10px
    border 1px solid #f0f0f0
    line-height 100%
    font-size 12px
  p
    font-size 12px
    color #333
    margin 10px 0
.list-item:nth-child(6n)
  margin-right 0
.list-info-tit
  float left
  color #333
  width 110px
  height 55px
  margin-left 5px
  margin-top 5px
  font-size 14px
  font-weight bold
.list-tag-blue
  display inline-block
  padding 2px 5px
  margin-top 10px
  margin-bottom 4px
  margin-right 3px
  color #81a2c3
  font-size 12px
  background-color #f4f9fc
.list-operation
  position absolute
  bottom 0
  left 0
  right 0
  border-top 1px solid #F7F7F7
  li
    display inline-block
    width 59px
    height 36px
    line-height 36px
    text-align center
    color #999
    cursor pointer
    font-size 12px
</style>
