<template>
  <div class="project-wrap">
    <div class="p-page-main">
      <div class="project-search">
        <input
          type="text"
          v-model="searchVal"
          v-on:focus="clearKeyworld"
          v-on:blur="hideKeyWorld"
          @input="selectKeyWord"
          @keyup.enter="fetchListData"
        >
        <span @click="fetchListData()">
          搜 索
        </span>
        <div
          class="search-wrap select-list"
          v-show="searchValList && searchValList.length > 0"
        >
          <ul>
            <li
              v-for="(item, index) in searchValList"
              :key="index"
              @click="selectProject(item)"
            >{{item.projectName}}</li>
          </ul>
        </div>
      </div>
      <div class="condition">
        <win-cascader
          v-if="projectAreaList.length > 0"
          optionTit="项目区域"
          v-model="projectArea"
          :lists="projectAreaList"
          @change="onChangeArea"
        />
        <win-select
          v-if="projectTypeList.length > 0"
          v-model="projectType"
          optionTit="项目类型"
          :lists="projectTypeList"
          @change="onChangeType"
        />
        <win-select
          v-if="xiangmudangciList.length > 0"
          v-model="xiangmudangci"
          optionTit="项目档次"
          :lists="xiangmudangciList"
          @change="onChangeGrade"
        />
        <win-select
          v-if="projectStateList.length > 0"
          v-model="projectState"
          optionTit="项目状态"
          :lists="projectStateList"
          @change="onChangeState"
        />
        <win-cascader
          v-if="projectNeedList.length > 0"
          v-model="projectNeed"
          optionTit="招商需求"
          :lists="projectNeedList"
          @change="onChangeNeed"
        />
        <win-select
          v-if="ShangyeMianjiList.length > 0"
          v-model="ShangyeMianji"
          optionTit="项目体量"
          :lists="ShangyeMianjiList"
          @change="onChangeVolume"
        />
        <win-select
          v-if="kaiYeShiJianList.length > 0"
          v-model="kaiYeShiJian"
          optionTit="开业年限"
          :lists="kaiYeShiJianList"
          @change="onChangeYears"
        />
      </div>
      <div
        class="fixed-box"
        :class="searchBarFixed"
        id="navTop"
      >
        <ul
          class="list-order"
          :class="{'is-fixed': titleFixed}"
        >
          <li
            :class="{'active': orderBy === '7'}"
            @click="changOrder('7')"
          >全部</li>
          <li
            :class="{'active': orderBy === '2'}"
            @click="changOrder('2')"
          >最近更新</li>
          <li>
            <el-popover
              v-model="showArea"
              placement="bottom"
              popper-class="popover"
              trigger="hover"
            >
              <ul class="popover-order">
                <li
                  :class="{'active': orderBy === '3'}"
                  @click="changOrder('3')"
                >面积从高到低排序</li>
                <li
                  :class="{'active': orderBy === '4'}"
                  @click="changOrder('4')"
                >面积从低到高排序</li>
              </ul>
              <div slot="reference">
                {{orderBy === '3' ? '面积从高到低排序' : (orderBy === '4' ? '面积从低到高排序' : '商业面积')}}
                <!-- <span class="icon-order-up icon-order-down"></span> -->
              </div>
            </el-popover>
          </li>
          <li>
            <el-popover
              v-model="showDate"
              placement="bottom"
              popper-class="popover"
              trigger="hover"
            >
              <ul class="popover-order">
                <li
                  :class="{'active': orderBy === '5'}"
                  @click="changOrder('5')"
                >时间从高到低排序</li>
                <li
                  :class="{'active': orderBy === '6'}"
                  @click="changOrder('6')"
                >时间从低到高排序</li>
              </ul>
              <div slot="reference">
                {{orderBy === '5' ? '时间从高到低排序' : (orderBy === '6' ? '时间从低到高排序' : '开业时间')}}
                <!-- <span class="icon-order-up icon-order-down"></span> -->
              </div>
            </el-popover>
          </li>
          <!-- 暂时隐藏 -->
          <!-- <li>
            <el-checkbox
              v-model="hasBrand"
              @change="changeBrand">品牌清单</el-checkbox>
          </li> -->
        </ul>
      </div>
      <div
        class="list-main"
        id="content"
      >
        <ul
          v-loading.fullscreen="listLoading"
          element-loading-text="加载中..."
          class="clearfix"
        >
          <list-item
            v-for="(item, key) in lists"
            :key="key"
            :item="item"
          />
        </ul>
      </div>
      <div
        class="noData"
        v-if="!listLoading && lists && lists.length === 0"
      >
        <p class="no-list-data"></p>
        <p>很抱歉，暂时无法找到符合条件的项目，您可以更新条件重新搜索</p>
      </div>
      <div
        class="w-page-sty"
        style="padding-bottom: 30px;"
      >
        <el-pagination
          background
          v-if="lists && lists.length > 0"
          layout="prev, pager, next"
          :current-page="pageNum"
          @current-change="projectPageClick"
          :page-size="pageSize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import winCascader from './WinCascader.vue'
import { mapGetters } from 'vuex'
import winSelect from './WinSelect.vue'
import listItem from './ListItem.vue'
export default {
  name: 'projectList',
  components: {
    winCascader,
    winSelect,
    listItem
  },
  watch: {
    // searchVal (newVal, oldVal) {
    //   this.fetchProject()
    // }
  },
  data () {
    return {
      projectId: '',
      searchBarFixed: '',
      orderBy: '7',
      hasBrand: '',
      titleFixed: false,
      searchVal: '请输入项目名称',
      searchValList: [],
      projectArea: [],
      projectAreaList: [],
      projectType: '',
      projectTypeList: [],
      xiangmudangci: '',
      xiangmudangciList: [{
        label: '高档',
        value: '高档'
      }, {
        label: '中高档',
        value: '中高档'
      }, {
        label: '中档',
        value: '中档'
      }, {
        label: '大众化',
        value: '大众化'
      }],
      ShangyeMianji: '',
      ShangyeMianjiList: [{
        label: '5万平米以下',
        value: '1'
      }, {
        label: '5-10万平米',
        value: '2'
      }, {
        label: '10-20万平米',
        value: '3'
      }, {
        label: '20万平米以上',
        value: '4'
      }],
      kaiYeShiJian: '',
      kaiYeShiJianList: [{
        label: '不满1年',
        value: '1'
      }, {
        label: '1-3年',
        value: '2'
      }, {
        label: '3-5年',
        value: '3'
      }, {
        label: '5-10年',
        value: '4'
      }, {
        label: '10年以上',
        value: '5'
      }],
      projectState: '',
      projectStateList: [{
        label: '已开业',
        value: '2072'
      }, {
        label: '未开业',
        value: '2071'
      }],
      projectNeed: [],
      projectNeedList: [],
      showArea: false,
      showDate: false,
      listLoading: false,
      lists: [],
      projectData: {},
      total: 0,
      pageNum: 1,
      pageSize: 60,
      zsxq_yt1: '',
      zsxq_yt2: '',
      qy_p: '',
      qy_c: '',
      qy_a: '',
      wuyelx: '2019',
      xmzt: '',
      key: '',
      isQianYue: 0
      // listData: {
      //   'pageNum': 1,
      //   'orderBy': '1',
      //   'pageSize': 60,
      //   'zsxq_yt1': '',
      //   'zsxq_yt2': '',
      //   'qy_p': '',
      //   'qy_c': '',
      //   'xmzt': '',
      //   'wuyelx': '',
      //   'isHaveLink': '',
      //   'ifdporyt': '',
      //   'key': '',
      //   'isQianYue': ''
      // }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  created () {
    this.listLoading = true
    this.initData()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = document.querySelector('#navTop').offsetTop || 0
      const contentTop = document.querySelector('#content').offsetTop || 0
      if (scrollTop > offsetTop) {
        this.searchBarFixed = 'isFixed'
      }
      if (scrollTop < contentTop) {
        this.searchBarFixed = ''
      }
    },
    onChangeArea () {
      this.listLoading = true
      if (this.projectArea.length === 1) {
        this.qy_p = this.projectArea[0]
        this.qy_c = ''
        this.qy_a = ''
      } else if (this.projectArea.length === 2) {
        this.qy_p = this.projectArea[0]
        this.qy_c = this.projectArea[1]
        this.qy_a = ''
      } else if (this.projectArea.length === 3) {
        this.qy_p = this.projectArea[0]
        this.qy_c = this.projectArea[1]
        this.qy_a = this.projectArea[2]
      } else {
        this.qy_p = ''
        this.qy_c = ''
        this.qy_a = ''
      }
      this.getProjectListFunc('condition')
    },
    onChangeType () {
      this.listLoading = true
      if (this.projectType === '2019') {
        this.wuyelx = '2019'
      }
      if (this.projectType === '8003') {
        this.wuyelx = '8003'
      }
      // this.projectType === '2019' ? this.wuyelx = '2019' : ''
      // this.projectType === '8003' ? this.wuyelx = '8003' : ''
      this.getProjectListFunc('condition')
    },
    onChangeGrade () {
      this.listLoading = true
      this.getProjectListFunc('condition')
    },
    onChangeVolume () {
      this.listLoading = true
      this.getProjectListFunc('condition')
    },
    onChangeYears () {
      this.listLoading = true
      this.getProjectListFunc('condition')
    },
    onChangeState () {
      this.listLoading = true
      this.xmzt = this.projectState
      this.getProjectListFunc('condition')
    },
    onChangeNeed () {
      this.listLoading = true
      if (this.projectNeed.length === 1) {
        this.zsxq_yt1 = this.projectNeed[0]
        this.zsxq_yt2 = ''
      } else if (this.projectNeed.length === 2) {
        this.zsxq_yt1 = this.projectNeed[0]
        this.zsxq_yt2 = this.projectNeed[1]
      } else {
        this.zsxq_yt1 = ''
        this.zsxq_yt2 = ''
      }
      this.getProjectListFunc('condition')
    },
    changOrder (type) {
      this.listLoading = true
      this.orderBy = type
      this.getProjectListFunc('condition')
    },
    changeBrand () {
      this.listLoading = true
      if (this.hasBrand) {
        this.isQianYue = 1
      } else {
        this.isQianYue = 0
      }
      this.getProjectListFunc('condition')
    },
    projectPageClick (val) {
      this.listLoading = true
      // console.log(val)
      this.pageNum = val
      this.getProjectListFunc('page')
    },
    selectKeyWord () {
      this.projectId = ''
      let searchVal = ''
      if (this.searchVal !== '' && this.searchVal !== '请输入项目名称') {
        searchVal = this.searchVal
      }
      if (searchVal) {
        const data = {
          projectName: searchVal,
          topNum: 7,
          wuyeLx: '购物中心,独立百货'
        }
        this.axios.post(api.getSearchProNameList, data)
          .then((res) => {
            this.searchValList = res.data.data
          })
      }
    },
    // fetchProject () {
    //   this.projectId = ''
    //   let searchVal = ''
    //   if (this.searchVal !== '' && this.searchVal !== '请输入项目名称') {
    //     searchVal = this.searchVal
    //   }
    //   if (searchVal) {
    //     let data = {
    //       'projectName': searchVal,
    //       'topNum': 7
    //     }
    //     this.axios.post(api.getSearchProNameList, data)
    //     .then((res) => {
    //       this.searchValList = res.data.data
    //     })
    //   }
    // },
    selectProject (item) {
      this.searchVal = item.projectName
      this.projectId = item.projectId
      this.searchValList = []
      this.fetchListData()
    },
    fetchListData () {
      this.key = this.searchVal
      this.listLoading = true
      this.getProjectListFunc('condition')
    },
    clearKeyworld () {
      if (this.searchVal === '请输入项目名称') {
        this.searchVal = ''
        this.projectId = ''
      }
    },
    hideKeyWorld () {
      if (this.searchVal === '') {
        this.searchVal = '请输入项目名称'
        this.searchValList = []
      }
    },
    initData () {
      this.axios.post(api.getAreaList)
        .then((res) => {
          // 区域
          this.projectAreaList = res.data.data.map((caseOne) => {
            const temOne = {
              value: caseOne.provinceId,
              text: caseOne.provinceName,
              children: caseOne.cities ? caseOne.cities.map((casetwo) => {
                const temTwo = {
                  value: casetwo.cityId,
                  text: casetwo.cityName,
                  children: casetwo.areaList ? casetwo.areaList.map((casethree) => {
                    const temThree = {
                      value: casethree.areaId,
                      text: casethree.areaName
                    }
                    return temThree
                  }) : []
                }
                if (casetwo.areaList.length === 0) {
                  temTwo.children = []
                }
                return temTwo
              }) : []
            }
            return temOne
          })
        })
      // 项目类型
      this.axios.post(api.getTypeList)
        .then((res) => {
          this.projectTypeList = res.data.data.map((item) => {
            const temobj = {
              label: item.name,
              value: item.id.toString()
            }
            return temobj
          })
        })
      // 招商需求
      this.axios.post(api.getProjectFormatList, { type: 2 })
        .then((res) => {
          this.projectNeedList = res.data.data.yeTaiListList.map((item) => {
            const temOne = {
              value: item.first.yeTaiName,
              text: item.first.yeTaiName,
              children: item.second ? item.second.map((option) => {
                const temTwo = {
                  value: option.yeTaiName,
                  text: option.yeTaiName,
                  children: []
                }
                return temTwo
              }) : []
            }
            return temOne
          })
        })
      this.axios.post(api.getProjectList,
        {
          pageNum: 1,
          orderBy: 7,
          pageSize: 60,
          zsxq_yt1: '',
          zsxq_yt2: '',
          qy_p: '',
          qy_c: '',
          qy_a: '',
          xmzt: '',
          key: '',
          wuyelx: '2019,8003',
          userid: this.user.userId,
          userType: this.user.userType,
          // 'isQianYue': 0
          xiangmudangci: this.xiangmudangci,
          kaiYeShiJian: this.kaiYeShiJian,
          ShangyeMianji: this.ShangyeMianji
        })
        .then((res) => {
          this.total = res.data.data.total
          this.lists = res.data.data.list.map((item) => {
            const list = {
              minPic: this.setMinImages(item.projectPic),
              hasRead: false,
              optionStr: this.setOptionStr(item),
              wuYeLx: item.wuYeLx,
              projectId: item.projectId,
              projectName: item.projectName,
              xmZhuangTai: item.xmZhuangTai,
              isDianPuZhaoZu: item.isDianPuZhaoZu,
              hasRemark: item.hasRemark,
              isYiQianYueBrand: item.isYiQianYueBrand,
              xiangmudangci: item.xiangmudangci
            }
            return list
          })
          this.searchValList = []
          this.listLoading = false
        })
    },
    // 获取项目列表
    getProjectListFunc (type) {
      if (type === 'condition') {
        this.pageNum = 1
      } else if (type === 'page') {
        window.scrollTo(0, 0)
      }
      let data = {}
      if (this.searchVal === '请输入项目名称') {
        this.key = ''
      }
      data = {
        pageNum: this.pageNum,
        orderBy: this.orderBy,
        pageSize: this.pageSize,
        zsxq_yt1: this.zsxq_yt1,
        zsxq_yt2: this.zsxq_yt2,
        qy_p: this.qy_p,
        qy_c: this.qy_c,
        qy_a: this.qy_a,
        xmzt: this.xmzt,
        key: this.key,
        wuyelx: this.projectType === '' ? '2019,8003' : this.wuyelx,
        userid: this.user.userId,
        userType: this.user.userType,
        // 'isQianYue': this.isQianYue
        xiangmudangci: this.xiangmudangci,
        kaiYeShiJian: this.kaiYeShiJian,
        ShangyeMianji: this.ShangyeMianji
      }
      this.axios.post(api.getProjectList, data)
        .then((res) => {
          this.total = res.data.data.total
          this.lists = res.data.data.list.map((item) => {
            const list = {
              minPic: this.setMinImages(item.projectPic),
              hasRead: false,
              optionStr: this.setOptionStr(item),
              wuYeLx: item.wuYeLx,
              projectId: item.projectId,
              projectName: item.projectName,
              xmZhuangTai: item.xmZhuangTai,
              isDianPuZhaoZu: item.isDianPuZhaoZu,
              hasRemark: item.hasRemark,
              isYiQianYueBrand: item.isYiQianYueBrand,
              xiangmudangci: item.xiangmudangci
            }
            return list
          })
          this.searchValList = []
          this.listLoading = false
        })
    },
    setOptionStr (item) {
      let option = ''
      const optionList = []
      item.kaiYeShiJian && (!item.shangYeMianji ? optionList.push(item.kaiYeShiJian) : optionList.push(item.kaiYeShiJian))
      item.shangYeMianji && (!item.kaiYeShiJian ? optionList.push(item.shangYeMianji + '万平方米') : optionList.push(item.shangYeMianji + '万平方米'))
      option = optionList.join('/')
      return option
    },
    setMinImages (projectPic, width = 150) {
      let temUrl = 'https://jscss.winshangdata.com/app/assets/no-pic.png'
      if (projectPic) {
        const i = projectPic.indexOf('?')
        if (i !== -1) {
          temUrl = projectPic.substring(0, i) + '?w=' + width + '&h=' + width
        } else {
          temUrl = projectPic + '?w=' + width + '&h=' + width
        }
      }
      return temUrl
    }
  }
}
</script>

<style lang="stylus">
.p-page-main
  width 1280px
  margin 0 auto
  position relative
  margin-top 24px
.project-search
  position relative
  margin-bottom 24px
  height 40px
  line-height 40px
  font-size 14px
  input
    width 1160px
    background-color #272930
    border 1px solid #272930
    height 100%
    box-sizing border-box
    color #e5e5e5
    text-align left
    padding-left 20px
  span
    position relative
    display inline-block
    background-color #3E3E45
    width 110px
    color #F4F4F4
    cursor pointer
    vertical-align top
    margin-left 10px
    padding-left 24px
    text-align center
    box-sizing border-box
    &:before
      position absolute
      top 50%
      left 22px
      transform translateY(-50%)
      font-family 'iconfont'
      font-size 22px
      font-style normal
      line-height normal
      -webkit-font-smoothing antialiased
      -webkit-text-stroke-width 0.2px
      display inline-block
      color #F4F4F4
      content '\e741'
  .search-wrap
    position absolute
    top 41px
    left 0
    width 99.8%
    max-height 340px
    padding-top 1px
    z-index 11
    overflow-y auto
    overflow-x hidden
    background #272930
    li
      text-align left
      padding-left 20px
      color #fff
.condition
  padding 24px 24px 10px 24px
  margin 14px auto
  background-color #272930
  // box-shadow 0px 0px 0.5px 0px #999
.fixed-box
  height 40px
.list-order
  // border solid 1px #333
  background-color #272930
  li
    display inline-block
    height 38px
    line-height 38px
    padding-left 20px
    padding-right 20px
    font-size 12px
    color #bebebe
    cursor pointer
    &:hover
      color #fff
      transition all 0.3s ease
  a
    color #333
    &:hover
      color #3585ff
      transition all 0.3s ease
  .active
    background-color #3a3c42
    color #fff
    transition all 0.3s ease
.popover
  background #272930
  border-color #333
.popover-order
  text-align center
  background-color #272930
  li
    padding-top 5px
    padding-bottom 5px
    font-size 12px
    color #bebebe
    cursor pointer
    &:hover
      color #fff
      transition all 0.3s ease
  .active
    color #fff
.popper__arrow
  display none !important
.list-main
  padding-top 25px
.isFixed
  position fixed
  top 0px
  z-index 9999
  width 1280px
  background-color #cccccc
  margin-top 0
  box-shadow 0 0 4px 0 #bbb
.noData
  text-align center
  height 400px
  p
    color #fff
    margin-top 80px
.list-order .icon-order-up
  font-size 14px
</style>

<style>
input::-webkit-input-placeholder {
  color: #ccc;
}
input::-moz-input-placeholder {
  color: #ccc;
}
input::-ms-input-placeholder {
  color: #ccc;
}
</style>
